import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';
import IntroCta from '../components/home/intro-cta';
import Carousel from '../components/home/carousel';
import ZipPay from '../components/home/zippay';
import Love from '../components/home/love';
import FastestWay from '../components/home/fastest-way';
import Network from '../components/home/network';
import Team from '../components/home/meet-the-team';
import CustomerReviews from '../components/home/customer-reviews';

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        homepage {
          structured_data
          seo {
            title
            description
            canonical
            og {
              description
              image
              title
              type
              url
            }
          }
        }
        zip {
          environment
          publicKey
        }
      }
    `
  );

  const { environment, publicKey } = data.zip;
  const { seo, structured_data } = data.homepage;

  return (
    <Layout showSiteIndex={true} hideHeaderFooter={true} showStickyButton={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData={structured_data}
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <IntroCta
        title="Car Repair Made <span>Easy.</span>"
        intro="Using only photos DingGo will get you quotes from local repairers near you."
        link="/quote"
        linkText="Get free quotes"
      />
      <Carousel />
      {environment !== '' && publicKey !== '' && (
        <ZipPay />
      )}
      <Love />
      <FastestWay />
      <Network />
      <Team />
      <CustomerReviews />
      {environment !== '' && publicKey !== '' && (
        <ZipPay />
      )}
    </Layout>
  );
};
export default IndexPage;
